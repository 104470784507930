@import "../../../../scss/var";
.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    font-weight: 700;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}
