@import "../../../../scss/var";
.dash_page {
  padding: 1rem 0;
  --extraHeight: 300px;
}
a {
  text-decoration: none;
  .dash_card {
    display: flex;
    gap: 0.6rem;
    flex-direction: column;
    height: 180px;
    border-radius: 12px;
    padding: 1rem 1.5rem;
    color: #525252;
    border-radius: 10px;
    border: 0.5px solid $lightGrey;
    background-color: $white;
    //   @include box;import { ReactComponent as Icon5 } from "../../../assests/icon5.svg";

    .dash_cardImg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      svg {
        font-size: 1.5em;
        color: $secondary;
      }
    }
    h5 {
      color: $secondary;
      font-size: 16px;

      font-weight: $fontNormal;
      line-height: 150%; /* 24px */
    }
    h3 {
      color: $black;
      font-weight: $fontBold;
      line-height: 150%; /* 42px */
      margin-bottom: 0px;
    }
  }
  .dash_cardLg {
    min-height: 550px;
    //   @include box;
  }
}
.dashboardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 25px;
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 20px 20px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  table {
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        .statusDiv {
          display: flex;
          align-items: center;
          span {
            width: 8px;
            height: 8px;
            margin-right: 12px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: 21px;
          }
        }
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.dash_cardView {
  flex-direction: row !important;
  height: auto !important;
  h5 {
    margin-bottom: 0;
  }
}

.search {
  position: relative;
  display: flex;
  gap: 10px;
  .searchField {
    margin-right: 10px;
    position: relative;
  }
  .crossIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: #fff;
    padding-left: 8px;
    svg {
      color: #2549e8;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    button {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    top: 13px;
    z-index: 9;
    left: 10px;
    @media only screen and (max-width: 767px) {
      top: 23px;
    }
  }
  input {
    padding-left: 30px;
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    background: $white;
    background-color: $white !important;
    padding-right: 10px !important;
    &::placeholder {
      color: $lightGrey;
      font-size: 12px;

      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
    &:global(.form-control) {
      min-height: 40px !important;
      color: $black !important;
      // padding-left: 30px !important;
    }
  }
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    font-weight: 700;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}

.orgnaizationDetails {
  align-items: self-start;
  .search {
    width: 40%;
    flex-wrap: wrap;
    justify-content: end;

    @media only screen and (max-width: 1420px) {
      width: 50%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
    button {
      border: 0.5px solid $lightGrey;
      span {
        svg {
          position: inherit;
          margin-right: 5px;
        }
      }
    }
  }
}

.searchQr {
  button {
    margin-bottom: 10px;
    span {
      svg {
        position: inherit;
        margin-right: 5px;
      }
    }
  }
}

.LogoName {
  .LogoBg {
    border: 0.5px solid $lightGrey;
    padding: 0px;
    background: $bgColor;
    height: 182px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    border-radius: 50%;
    margin: 0px auto 10px;
    img {
      width: 100%;
      height: 180px;
      border-radius: 50%;
      object-fit: contain;
    }
    color: $lightGrey;
  }
  .NameText {
    display: flex;
    h6 {
      border-radius: 4px;
      background: #e7e6ff;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      color: #635bff;
      margin-right: 15px;
    }
    h5 {
      background-color: #e4ffeb;
      width: 100%;
      border-radius: 4px;
      color: #34c759;
      font-size: 14px;

      font-weight: 400;
      line-height: 150%; /* 21px */
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border: 0.5px solid #a0e8b1;
      span {
        width: 8px;
        height: 8px;
        display: block;
        background-color: #34c759;
        border-radius: 50%;
        margin-right: 10px;
      }
      &.error {
        background-color: #d4d5d587;
        color: $secondary;
        border: 0.5px solid $lightGrey;
        span {
          background-color: $secondary;
        }
      }
    }
  }
  .copyText {
    border: 1px solid $lightGrey;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    span {
      color: $secondary;

      text-align: center;
      font-size: 13px;

      font-weight: 400;
      line-height: 150%; /* 19.5px */
    }
  }
}

.rightSides {
  position: relative;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
  }
  .rightName {
    h6 {
      color: $secondary;
      font-size: 16px;

      font-weight: 400;
      line-height: 150%; /* 24px */
      display: flex;
      align-items: center;
      span {
        width: 8px;
        height: 8px;
        margin-left: 15px;
        margin-right: 5px;
        display: block;
        border-radius: 50%;
        background-color: $red;
        &.is_active {
          background-color: $green;
        }
      }
    }
    h2 {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .tipButtons {
    position: absolute;
    top: -21px;
    right: 15px;
    @media only screen and (max-width: 992px) {
      position: inherit;
      margin-top: 10px;
      top: 0;
      right: 0px;
      margin-bottom: 20px;
    }
    button {
      background-color: $bgColor;
      border: 0.5px solid $lightGrey;
      height: 30px;
      color: $secondary;
      font-size: 12px;
      line-height: 150%; /* 18px */
      margin-right: 12px;
      border-radius: 0 0 4px 4px;
      font-style: italic;
      @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
  .cards {
    display: grid;
    gap: 10px;
    grid-template-columns: auto auto auto auto;
    @media only screen and (max-width: 1400px) {
      gap: 7px;
    }
    .cardsBox {
      border-radius: 4px;
      border: 0.5px solid $lightGrey;
      padding: 12px;
      background: #fff;
      width: 100%;
      // @media only screen and (max-width: 1200px) {
      //   width: 48%;
      // }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 0px;
      }
      h4 {
        color: $black;
        font-size: 14px;
        word-wrap: break-word;
        font-weight: 500;
        line-height: 150%; /* 21px */
        margin-bottom: 0;
      }
      .colorTheme {
        display: flex;
        justify-content: space-between;
        .colorLeft {
        }
        .colorRight {
          span {
            width: 30px;
            height: 30px;
            display: block;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.red {
  background-color: #34c759;
}

.green {
  background-color: #ff4218;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dotEnabled {
  background-color: $secondary; /* Choose your color */
}
.dotRestricted {
  background-color: #ff4218; /* Choose your color */
}
.dotNotSetup {
  background-color: #34c759; /* Choose your color */
}

.dotRectSoon {
  background-color: #2549e8; /* Choose your color */
}

.dotNotSets {
  background-color: $secondary; /* Choose your color */
}

.dashboardHeadings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: start;
  }
  .filters {
    display: flex;
    align-items: center;
    margin-left: 5px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    label {
      color: $secondary;
      text-align: center;
      font-size: 12px;

      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
  }

  button {
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin: 0;
    color: $black;
    font-size: $fontLG;

    font-weight: $fontExtraBold;
    line-height: 150%; /* 30px */
  }
}

.boxTableNone {
  box-shadow: none;
  padding: 10px 20px;
}

.tipsWrap {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
  @include max(1060) {
    justify-content: flex-start;
  }
}
