@import "../../scss/var";

.dashboardTable_outer {
  position: relative;
  width: 100%;
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 8px 20px;
  max-height: 100%;
  .dashboardHeadings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: start;
    }
    .filters {
      display: flex;
      align-items: center;
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        color: $secondary;
        text-align: center;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
    }

    button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;

      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    thead {
      vertical-align: middle;
      th {
        white-space: nowrap;
        color: $black;
        text-align: start;
        font-size: $fontSM;
        text-transform: capitalize;
        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;
        font-weight: $fontNormal;
        min-width: 80px;
        line-height: 150%; /* 21px */
        padding: 1rem;
        .statusDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-bottom: 16px;
          span {
            width: 8px;
            height: 8px;
            margin-right: 4px;
            display: block;
            border-radius: 50%;

            left: 0;
            top: 21px;
          }
        }
        .statusDivs {
          display: flex;
          align-items: center !important;
          // justify-content: center;
          margin-bottom: 16px;
          span {
            width: 8px;
            height: 8px;
            margin-right: 4px;
            display: block;
            border-radius: 50%;

            left: 0;
            top: 21px;
          }
        }
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.textStyle {
  text-align: center !important;
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}

.red {
  background-color: $red;
}
.green {
  background-color: $green;
}
.gray {
  background-color: $secondary;
}
// p {
//   margin-top: 1rem !important;
// }
