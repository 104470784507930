@import "../../scss/var";
.dash_page {
  padding: 1rem 0;
  --extraHeight: 400px;
}
.dashboardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 25px;
  }
}
.tipsWrap {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  @include max(1060) {
    justify-content: flex-start;
  }
}
.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 20px;
  .dashboardHeadings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      font-size: $fontLG;
      font-weight: $fontExtraBold;
      color: $black;
      padding: 20px 10px;
    }
  }
  table {
    thead {
      th {
        color: $black;
        font-size: $fontSM;
        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;
        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}
.dash_cardView {
  flex-direction: row !important;
  height: auto !important;
  h5 {
    margin-bottom: 0;
  }
}
.search {
  position: relative;
  display: flex;
  gap: 10px;
  .searchField {
    margin-right: 10px;
    position: relative;
  }
  .crossIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: #fff;
    padding-left: 8px;
    svg {
      color: #FD7014;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    width: 100%;
    button {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    top: 13px;
    z-index: 9;
    left: 10px;
    @media only screen and (max-width: 767px) {
      top: 23px;
    }
  }
  input {
    padding-left: 30px;
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    background: $white;
    background-color: $white !important;
    padding-right: 10px !important;
    &::placeholder {
      color: $lightGrey;
      font-size: 12px;
      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
    &:global(.form-control) {
      min-height: 40px !important;
      color: $black !important;
      // padding-left: 30px !important;
    }
  }
}
.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;
    color: $secondary;
    font-size: $fontSM;
    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }
  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}
.cardsWrap {
  @include flexContainer(calc(100% / 2), 11px, 22px, flex-start);
  .card {
    min-height: 100px;
    border: 0.5px solid $lightGrey;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .amount {
    font-size: $fontXL;
    font-weight: $fontBold;
  }
  .title {
    color: $black;
    font-size: $fontLG;
    font-weight: $fontBold;
  }
  @include max(1199) {
    .amount {
      font-size: 24px;
    }
  }
  @include max($lg) {
    --width: calc(100% / 2);
  }
  @include max($md) {
    --width: 100%;
  }
  margin-top: 25px;
  margin-bottom: 25px;
}
// search icon
.search {
  position: relative;
  display: flex;
  .searchField {
    margin-right: 10px;
    position: relative;
  }
  .crossIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: #fff;
    padding-left: 8px;
    svg {
      color: #FD7014;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    button {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    top: 13px;
    z-index: 9;
    left: 10px;
    @media only screen and (max-width: 767px) {
      top: 23px;
    }
  }
  input {
    width: 100%;
    padding-left: 30px;
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 40px;
    padding-right: 10px;
    background: $white;
    appearance: none;
    &::placeholder {
      color: $lightGrey;
      font-size: 12px;
      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
}


.cardsWrap3 {
  --boxNumber: 6;
  @include flexContainer(calc(100% / var(--boxNumber)), 7px, 15px, flex-start);
  .card {
    height: 100%;
    border: 0.5px solid $lightGrey;
    padding: 20px;
    border-radius: 10px;
    &.revenueCard {
      height: auto;
      width: 100%;
      aspect-ratio: 1/0.9;
      background-image: url("../../assests/images/dashboard/totalRevenueBg.svg");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        order: 1;
        text-align: center;
        margin-top: 10px;
        font-size: $fontSM;
      }
      .amount {
        order: 2;
        color: $white;
        height: 32px;
        @include max($xl3) {
          margin-top: 0;
          height: 30px;
          line-height: 34px;
        }
      }
      @include max($sm) {
        svg {
          height: 25px;
          width: 25px;
        }
        .title {
          font-size: $fontXSM;
        }
        .amount {
          font-size: 14px;
          height: 24px;
        }
      }
    }
  }
  .amount {
    font-size: 20px;
    font-weight: $fontBold;
    margin-top: 6px;
  }
  .title {
    color: $secondary;
  }
  @include max($xl3) {
    --boxNumber: 5;
  }
  @include max($xl1) {
    --boxNumber: 4;
  }
  @include max($lg) {
    --boxNumber: 3;
  }
  @include max($md) {
    --boxNumber: 2;
  }
  @include max($sm) {
    .amount {
      font-size: 24px;
    }
    .title {
      font-size: 14px;
    }
  }
  margin-top: 30px;
  margin-bottom: 30px;
}