@import "../../scss/var";
.steps {
  ul {
    display: inline-flex;
    padding: 0;
    list-style: none;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    width: 100%;
    margin: 0 auto;
    li {
      .numberLabel {
        text-align: center;
        position: relative;
        img {
          position: absolute;
          right: -53px;
          top: 25px;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
        h4 {
          text-align: center;
          border: 1px solid $lightGrey;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 auto 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          padding-top: 3px;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
        h5 {
          color: $secondary;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .activeStep {
      h5 {
        color: $black !important;
      }
      h4 {
        border-color: $black !important;
        color: $black !important;
      }
    }
  }
}
