@import "../../../scss/var";

.login {
  max-width: 440px;
  margin: auto;
}
.login_ByGoogle {
  flex-basis: 420px;
  .formTitle {
    margin: 1rem 0 2rem;
    img {
      width: 45px;
    }
    h2 {
      text-align: center;
      font-size: $fontLG;
    }
  }
}
.formFields {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .btnWrap {
    display: flex;
    justify-content: flex-end;
  }
}

.createRegister {
  margin-top: 1.4rem;
  font-size: $fontSM;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
