@import "../../../../scss/var";



.addCateg {
  position: relative;
  label {
    color: $black;
    font-size: 14px;

    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  .uploadInput {
    min-height: 50px;
    border: 0.5px solid $lightGrey;
    border-radius: 6px;
    background-color: $inputBgColor;
    display: block;
    input {
      display: none;
    }
    h6 {
      font-size: 14px;
      padding: 0px 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
      white-space: nowrap;
      height: 50px;
      margin: 0;
      line-height: 50px;
    }
  }
  input {
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 50px;
    background: $inputBgColor;
    width: 100%;
    padding: 12px;
    &::placeholder {
      color: $lightGrey;
      font-size: 14px;

      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .btnBrowse {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 34px;
    padding: 0 12px !important;
    height: 30px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  // gap: 2rem  ; 
}

.addCategBox {
  border: 0.5px solid $lightGrey;
  background: #fff;
  width: 69.767px;
  min-height: 69.767px;
  border-radius: 10.465px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .closeIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    border: 1px solid $lightGrey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: $inputBgColor;
    cursor: pointer;
    svg {
      color: #676d7a;
      fill: #676d7a;
    }
  }
}
.placeColor {
  color: $lightGrey;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.react-partner {
  min-height: 44px !important;
}
.inputHeading {
  color: $black;
  font-family: Graphik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 0px;
}
.error {
  position: absolute;
  color: $danger;
  right: 0;
  top: 100%;
  font-size: 12px;
  font-weight: 400;
}
