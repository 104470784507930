@import "../../../scss/var";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 1.5rem;
  }
  .modalContent {
    color: #222;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btnRight {
      background: #00a3ff;
      border-color: #00a3ff;
    }
    .btnLeft {
      color: #00a3ff;
      border-color: #00a3ff;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .modalTitle {
    text-align: center;
    .icn {
      margin-bottom: 6px;
      font-size: 2.5rem;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      background-color: rgba(red, 0.1);
      color: red;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      margin-bottom: 0;
    }
  }
}

:global(.modal-title) {
  color: $black;
  font-size: 16px !important;

  font-weight: 600;
  line-height: 150%;
}
:global(.modal-footer) {
  justify-content: center;
  border: none;
  button {
    padding: 0 25px;
  }
}

.addCateg {
  label {
    color: $black;
    font-size: 14px;

    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  input {
    border-radius: 6px;
    border: 0.5px solid #a9b0bf;
    height: 50px;
    background: $inputBgColor;
    width: 100%;
    padding: 12px;
  }
}

.addEditModal {
  :global(.modal-dialog) {
    width: 340px;
    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}
