@import "../../scss/var";

.heading {
  div {
    color: $black;
    display: flex !important;
    font-weight: $fontExtraBold;
    line-height: 150%; /* 24px */
    justify-content: space-between !important;
  }
}

.TipAmount {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  li {
    width: 48%;
    margin-bottom: 10px;
    margin-top: 10px;
    p {
      margin-bottom: 10px;
    }
    h4 {
      color: $black;
      font-size: $fontLG;

      font-weight: $fontBold;
      line-height: 150%; /* 30px */
    }
  }
}

.FooterModal {
  border: 0;
  padding-top: 0;
  justify-content: center;
  button {
    width: 114px;
  }
}
