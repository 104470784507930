@import "../../../../scss/var";
// @mixin cardStyle {
//   background-color: #fff;
//   padding: 1rem 1.5rem;
//   border-radius: 8px;
//   color: $titleColor;
//   box-shadow: 0 0 18px -6px rgba(black, 0.25);
//   cursor: pointer;
//   transition: 0.15s linear;
//   &:hover {
//     box-shadow: 0px 0px 26px -6px rgba(black, 0.3);
//   }
// }
.dash_page {
  padding: 1rem 0;
}

.dashboardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
    h2 {
      font-size: 22px;
    }
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 20px;
  // box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  .dashboardHeadings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: start;
    }
    .filters {
      display: flex;
      align-items: center;
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        color: $secondary;
        text-align: center;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
    }

    button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;

      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    margin-bottom: 0;
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        .statusDiv {
          display: flex;
          align-items: center;
          span {
            width: 8px;
            height: 8px;
            margin-right: 12px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: 21px;
          }
        }
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.dash_cardView {
  flex-direction: row !important;
  height: auto !important;
  h5 {
    margin-bottom: 0;
  }
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    font-weight: 700;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}

.pagnNew {
  border-top: 0.5px solid #dee2e6;
  margin-top: 0px;
  padding-top: 18px;
}

.manageCards {
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 12px;
  }
  .cardsCards {
    border-radius: 4px;
    border: 0.5px solid #a9b0bf;
    padding: 10px 12px;
    background: #fff;
    width: 201px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    h2 {
      color: #676d7a;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-bottom: 0px;
    }
    p {
      color: $black;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      margin-bottom: 0;
    }
  }
}
