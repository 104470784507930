@import "src/scss/var";

.pageHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
}
