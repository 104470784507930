@import "../../scss/var";
.layout {
  background-color: $skyBlue;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
}
.layout_wrap {
  display: flex;
  background-color: $skyBlue;
  justify-content: center;
  align-items: center;
  .layout_left {
    border-radius: 1rem 0 0 1rem;
    img {
      border-radius: inherit;
    }
  }
  .layout_right {
    position: relative;
    width: 477px;
    @include max(767) {
      width: 100%;
    }
    .layout_Top {
      background-color: $white;
      padding: 1rem 1rem 1rem;
      margin-bottom: 5px;
      border-radius: 6px;
      height: 65px;
      position: relative;
      &:after {
        width: 20px;
        height: 20px;
        background-color: $skyBlue;
        content: "";
        border-radius: 100%;
        position: absolute;
        bottom: -12px;
        left: -10px;
      }
      &:before {
        width: 20px;
        height: 20px;
        background-color: $skyBlue;
        content: "";
        border-radius: 100%;
        position: absolute;
        bottom: -12px;
        right: -10px;
      }
      .logo {
        border-radius: 10px;
        background: $skyBlue;
        width: 183px;
        height: 63px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 33px;
      }
    }
    .layout_Bottom {
      background-color: $white;
      padding: 2rem 1rem 2rem;
      border-radius: 6px;
    }
    @include min(992) {
      max-height: 484px;
    }
    @include min(1200) {
      max-height: 576px;
    }
    @include min(1400) {
      max-height: 670px;
    }
    @include min(1600) {
      max-height: 760px;
    }
  }
  @include max(991) {
    flex-wrap: wrap;
  }
}
