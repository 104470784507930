@import "../../scss/var";

.heading {
  div {
    color: $black;

    font-weight: $fontExtraBold;
    line-height: 150%; /* 24px */
  }
}

.TipAmount {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0;
  justify-content:center ;
  li {
    width: 48%;
    // margin-bottom: 10px;
    // margin-top: 10px;
    p {
      margin-bottom: 10px;
    }
    h4 {
      color: $black;
      font-size: $fontLG;

      font-weight: $fontBold;
      line-height: 150%; /* 30px */
    }
  }
}

.FooterModal {
  display: flex;
  justify-content: center;
}

.tipModal {
  :global(.modal-dialog) {
    width: 400px;
    @media only screen and (max-width: 767px) {
      margin: 0 auto;
    }
    @media only screen and (max-width: 380px) {
      width: 315px;
    }
  }
  :global(.modal-footer) {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 15px;
    justify-content: center;
    border: none;
  }
}
.tipperModal {
  :global(.modal-dialog) {
    width: 440px !important;
    @media only screen and (max-width: 767px) {
      margin: 0 auto;
    }
    @media only screen and (max-width: 380px) {
      width: 315px;
    }
  }
  :global(.modal-footer) {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 15px;
    justify-content: center;
    border: none;
  }
}
