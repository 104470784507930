@import "../../../../scss/var";

.dash_page {
  padding: 1rem 0;
}

.dashboardHeading {
  p {
    margin-bottom: 0;
  }
}

.differentClass {
  background-color: $black;

  display: flex;
  justify-content: center;
  align-items: center;
  .innerWrap {
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    border: 7px dotted #282f40;
    border-radius: 10px;
    width: 100%;
  }
  h3 {
    color: $white;
    text-align: center;
  }
  h5 {
    margin-bottom: 0;
  }
  .dash_cardContent {
    flex-direction: column-reverse;
    display: flex;
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid #a9b0bf;
  background: var(--White, #fff);
  padding: 20px;
  .dashboardHeadings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;
      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.cardsWrap {
  --boxNumber: 6;
  @include flexContainer(calc(100% / var(--boxNumber)), 7px, 15px, flex-start);
  .card {
    height: 100%;
    border: 0.5px solid $lightGrey;
    padding: 20px;
    border-radius: 10px;
    &.revenueCard {
      height: auto;
      width: 100%;
      aspect-ratio: 1/0.9;
      background-image: url("../../../../assests/images/dashboard/totalRevenueBg.svg");
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        order: 1;
        text-align: center;
        margin-top: 10px;
        font-size: $fontSM;
      }
      .amount {
        order: 2;
        color: $white;
        height: 32px;
        @include max($xl3) {
          margin-top: 0;
          height: 30px;
          line-height: 34px;
        }
      }
      @include max($sm) {
        svg {
          height: 25px;
          width: 25px;
        }
        .title {
          font-size: $fontXSM;
        }
        .amount {
          font-size: 14px;
          height: 24px;
        }
      }
    }
  }
  .amount {
    font-size: 20px;
    font-weight: $fontBold;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .title {
    color: $secondary;
  }
  @include max($xl3) {
    --boxNumber: 5;
  }
  @include max($xl1) {
    --boxNumber: 4;
  }
  @include max($lg) {
    --boxNumber: 3;
  }
  @include max($md) {
    --boxNumber: 2;
  }
  @include max($sm) {
    .amount {
      font-size: 24px;
    }
    .title {
      font-size: 14px;
    }
  }
  margin-top: 30px;
  margin-bottom: 30px;
}

.stripeId{
  align-items: center;
    border: 1px solid #a9b0bf;
    border-radius: 4px;
    display: flex;
    height: 38px;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
    span{
      color: #676d7a;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    text-align: center
    }
}

.tipsWrap {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include max(1060) {
      justify-content: flex-start;
    }
  }
