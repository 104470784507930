@import "../../../../src//scss//var";

.payment_wrap {
  background-color: $grey;
  padding: 25px;
  border-radius: 10px;

  .payment_content {
    content: "";
    border-radius: 10px;
    height: 210px;
    width: 210px;
    background-color: $white;
    position: relative;

    .innerContent {
      position: absolute;
      top: 58px;
      left: 50px;
    }
    .iconDiv {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      background-color: #d6d4ee;
      position: absolute;
      right: -16px;
      top: -14px;
    }
    .successDiv {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      background-color: #b9e5d6;
      position: absolute;
      right: -16px;
      top: -14px;
    }
    .rejectDiv {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      background-color: #f4bbb3;
      position: absolute;
      right: -16px;
      top: -14px;
    }
    .addIcon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      background-color: #635bff;
    }
    .successIcon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      background-color: #3acf9a;
    }
    .rejectIcon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      background-color: #ff4026;
    }
  }
  .head {
    color: $black;
    font-size: 20px;
    font-weight: 600;
  }
  .note {
    color: $secondary;
    font-weight: 600;
  }
  .right {
    @include max($xl5) {
      padding-left: 80px;
    }

    @include max($xl1) {
      padding: 20px 10px;
    }
  }
}
