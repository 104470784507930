@import "../../../scss/var";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 1.5rem;
  }
  .modalContent {
    color: $brown;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btnRight {
      background: #00a3ff;
      border-color: #00a3ff;
    }
    .btnLeft {
      color: #00a3ff;
      border-color: #00a3ff;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .modalTitle {
    text-align: center;
    .icn {
      margin-bottom: 6px;
      font-size: 2.5rem;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      background-color: rgba(red, 0.1);
      color: red;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      margin-bottom: 0;
    }
  }
}

:global(.modal-title) {
  color: $black;
  font-size: 16px !important;

  font-weight: 600;
  line-height: 150%;
}
:global(.modal-footer) {
  justify-content: center;
  border: none;
  margin-top: 10px;
  button {
    padding: 0 25px;
  }
}

.addCateg {
  position: relative;
  label {
    color: $black;
    font-size: 14px;

    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  .uploadInput {
    height: 50px;
    margin-bottom: 2px;
    border: 0.5px solid $lightGrey;
    border-radius: 6px;
    background-color: $inputBgColor;
    display: block;
    input {
      display: none;
    }
    h6 {
      font-size: 14px;
      padding: 0px 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
      white-space: nowrap;
      height: 50px;
      margin: 0;
      line-height: 50px;
    }
  }
  input {
    border-radius: 6px;
    border: 0.5px solid #a9b0bf;
    height: 50px;
    background: $inputBgColor;
    width: 100%;
    padding: 12px;
    &::placeholder {
      color: #a9b0bf;
      font-size: 14px;

      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .btnBrowse {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 34px;
    padding: 0 12px !important;
    height: 30px;
  }
}

.addEditModal {
  :global(.modal-dialog) {
    width: 340px;
    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
}

.error {
  color: red;
  font-size: 14px;
}

.butonSteps {
  display: flex;
  gap: 10px;
  justify-content: center;
  button {
    margin: 0 !important;
    color: #fff;
    padding: 0 35px;
  }
}

.steps {
  margin-bottom: 12px;
  ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    li {
      margin: 10px 12px;
      .numberLabel {
        text-align: center;
        position: relative;

        img {
          position: absolute;
          right: -53px;
          top: 25px;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
        h4 {
          text-align: center;
          border: 1px solid $lightGrey;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 auto 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
        h5 {
          color: $secondary;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .activeStep {
      h5 {
        color: $black !important;
      }
      h4 {
        border-color: $black !important;
        color: $black !important;
      }
    }
  }
}

.copyUrl {
  max-height: 700px;
  overflow: auto;
  padding: 10px 20px;
  .qrBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #a9b0bf;
    padding: 12px;
    margin-bottom: 15px;
    background: #fff;
    @media only screen and (max-width: 576px) {
      flex-direction: column;
      gap: 20px;
      align-items: start;
    }
    .leftQr {
      flex-shrink: 1;
      flex-basis: 50%;
      display: flex;
      // align-items: center;
      justify-content: start;
      gap: 20px;
      p {
        margin: 0px;
        word-break: break-all;
        color: $black;
        font-size: 16px;
        font-weight: 500;
        @media (max-width: 576px) {
          width: 60%;
        }
      }
    }
    .rightQr {
      flex-basis: 50%;
      flex-shrink: 1;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 20px;
      span {
        cursor: pointer;
        margin: 0px;
        color: $black;
        font-size: 14px;
        font-weight: 500;
      }
      button {
        border-radius: 6px;
        border: 0.5px solid #a9b0bf;
        height: 40px;
        padding: 0 10px;
        background: $bgColor;
        color: $black;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.addCategBox {
  border: 0.5px solid #a9b0bf;
  background: #fff;
  width: 69.767px;
  min-height: 69.767px;
  border-radius: 10.465px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2px;
  .closeIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    border: 1px solid #a9b0bf;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: $inputBgColor;
    cursor: pointer;
    svg {
      color: #676d7a;
      fill: #676d7a;
    }
  }
  img {
    border-radius: 10.465px;
  }
}

.stepsTwos {
  ul {
    width: 100% !important;
    li {
      width: 33%;
      .numberLabel {
        img {
          right: -24px;
        }
      }
    }
  }
}

.DollorSign {
  position: relative;
  span {
    color: $black;
    font-size: 14px;

    font-weight: 600;
    line-height: 150%; /* 21px */
    position: absolute;
    top: 15px;
    left: 15px;
  }
  input {
    padding-left: 30px;
  }
}

.fontColorss {
  border-radius: 6px;
  border: 0.5px solid #a9b0bf;
  height: 50px;
  background: $inputBgColor;
  width: 100%;
  padding: 12px;
  p {
    color: $black;
    font-size: 14px;
    line-height: 150%; /* 21px */
  }
}

.customCountryDropdown {
  /* Define your custom styles here */
  position: absolute;
  top: 0; /* Adjust as needed */
  left: 0; /* Adjust as needed */
  z-index: 1000; /* Ensure it's on top of other elements */
}

.placeColor {
  color: #a9b0bf;
  font-size: 14px;

  font-weight: 400;
}

.stepWrap {
  padding: 18px 0 24px;
}

.loadingWrap {
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
