// colors
$primary: #2549E8;
$secondary: #676d7a;
$lightGrey: #a9b0bf;
$white: #fff;
$black: #0a0e18;
$red: #ff4218;
$green: #34c759;
$brown: #222;
$bgColor: #ededed;
$inputBgColor: #f9f9f9;
$lightBlue: #00a3ff;
$danger: #d22b2b;
$grey: #efefef;
$skyBlue:#eaf5ff;
// fontSize
$fontXSM: 12px;
$fontSM: 14px;
$fontMD: 18px;
$fontLG: 20px;
$fontXL: 28px;
$fontXXL: 40px;

// fontWeight
$fontNormal: 400;
$fontBold: 500;
$fontExtraBold: 600;

// responsive widths
$sm: 567;
$md: 767;
$lg: 991;
$xl: 1099;
$xl1: 1280;
$xl2: 1365;
$xl3: 1399;
$xl5: 1599;

// responsive media functions
@mixin min($value) {
  @media screen and (min-width: ($value + 1 + "px")) {
    @content;
  }
}
@mixin max($value) {
  @media screen and (max-width: ($value + "px")) {
    @content;
  }
}
@mixin minMax($min, $max) {
  @media (min-width: ($min + "px")) and (max-width: ($max + "px")) {
    @content;
  }
}

// make item center
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

// custom responsive flex container
@mixin flexContainer($width, $space, $gap, $jc) {
  --width: #{$width};
  --space: #{$space};
  --gap: #{$gap};
  --jc: #{$jc};
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--jc);
  margin: 0 calc(-1 * var(--space));
  gap: var(--gap) 0;
  > * {
    padding: 0 var(--space);
    flex-basis: var(--width);
    max-width: var(--width);
  }
}

@mixin modalBox {
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.15);
}
