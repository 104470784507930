@import "./var";

.field_wrap {
  position: relative;
  label {
    font-size: $fontSM;
    font-weight: 500;
    margin-bottom: 2px;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
  }

  .input_wrap {
    position: relative;
    .field_icon {
      position: absolute;
      top: 50%;
      left: 28px;
      transform: translate(-50%, -50%);
    }
    .input_child {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
    .password_icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .amount_input {
    input {
      text-align: start !important;
      padding-left: 35px !important;
      text-align: start !important;
      padding-left: 35px !important;
      box-shadow: none;
      border-radius: 8px;
      min-height: 50px;
      padding: 8px 14px;
      background: #f9f9f9;
      line-height: 1.25;
      resize: none;
      width: 100%;
      border: 0.5px solid $lightGrey;
      &:focus {
        border-color: $primary;
        outline: $primary;
      }
      &::placeholder {
        color: $lightGrey;
      }
      &.has_error {
        border-color: $danger;
        &:focus {
          border-color: rgba($danger, 0.5);
          box-shadow: 0px 0px 0px 4px rgba($danger, 0.3),
            0px 1px 2px 0px rgba($danger, 0.3);
        }
      }
    }
  }
  input,
  textarea {
    &.form-control {
      box-shadow: none;
      border-color: $lightGrey;
      border-radius: 8px;
      min-height: 50px;
      padding: 8px 14px;
      background: $inputBgColor;
      line-height: 1.25;
      color: $secondary;
      resize: none;
      &.icon {
        padding-left: 44px;
      }
      &:focus {
        border-color: $primary;
      }
      &::placeholder {
        color: $lightGrey;
      }
      &.has_error {
        border-color: $danger;
        &:focus {
          border-color: rgba($danger, 0.5);
          box-shadow: 0px 0px 0px 4px rgba($danger, 0.3),
            0px 1px 2px 0px rgba($danger, 0.3);
        }
      }
    }
  }
  textarea {
    &.form-control {
      padding: 16px 14px;
    }
  }
  select {
    &.form-select {
      box-shadow: none;
      border-color: $lightGrey;
      border-radius: 8px;
      min-height: 44px;
      padding: 8px 14px;
      color: $secondary;
      resize: none;
    }
  }
  .error {
    position: absolute;
    color: $danger;
    right: 0;
    font-size: $fontXSM;
    top: 100%;
    width: max-content;
  }
}

.custom_btn {
  font-weight: 500;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  position: relative;
  min-width: 110px;
  .loader {
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.loading {
    color: transparent !important;
  }
  &.disabled {
    opacity: 0.9;
  }
  // font-size: 16px;
  &.btn {
    line-height: 1;
    min-height: 40px;
    padding: 9px 18px;
    font-size: $fontSM;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      // color: $white;
    }
  }
  &.btn-primary {
    color: #fff;
  }
  &.btn-outline-light {
    background-color: transparent;
    color: $primary;
    border-color: $lightGrey;
  }
}
.react_select_wrap .react_select__indicator {
  color: $black;
}
.react_select_wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0px !important;
  .form-label {
    margin: 0;
    font-size: $fontXSM;
    color: $secondary;
    margin-right: 7px;
  }
  .react_select__control {
    box-shadow: none;
    border-color: $lightGrey;
    border-radius: 6px;
    height: 30px;
    width: 140px;
    padding: 3px 8px;
    line-height: 1.5;
    background: $bgColor;
    color: $black;
    resize: none;
    font-size: $fontXSM;
    &:hover {
      border-color: $lightGrey;
    }
  }
  .react_select__control--menu-is-open {
    border-color: $lightGrey;
  }
  .react_select__indicator {
    padding: 0;
  }
  .react_select__value-container {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .react_select__menu {
    fill: #fff;
    min-width: 12rem;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    box-shadow: none;
    padding: 2px 0;
    z-index: 999;
  }
  &.form_select {
    flex-direction: column;
    align-items: normal;
    .form-label {
      font-size: $fontSM;
      color: $black;
      font-weight: $fontBold;
    }
    .react_select__control {
      height: auto;
      min-height: 50px;
      max-height: 90px;
      width: 100%;
      padding: 3px 12px;
      line-height: 1.5;
      background: $inputBgColor;
      color: $black;
      resize: none;
      font-size: $fontSM;
      &:hover {
        border-color: $lightGrey;
      }
      .react_select__value-container {
        max-height: 80px;
        overflow: auto;
      }
    }
    .has_error {
      .react_select__control {
        border-color: $danger;
      }
      &:focus {
        border-color: rgba($danger, 0.5);
        box-shadow: 0px 0px 0px 4px rgba($danger, 0.3),
          0px 1px 2px 0px rgba($danger, 0.3);
      }
    }
  }
  .error {
    position: absolute;
    color: $danger;
    right: 0;
    font-size: $fontXSM;
    top: 100%;
  }
}

.pagination {
  display: flex;
  gap: 10px;
  margin: auto;
  li {
    font-size: 12px;
    cursor: pointer;
  }
  .rc-pagination-item-active {
    a {
      color: $primary;
    }
    &:hover {
      color: $primary;
    }
  }
  .rc-pagination-disabled {
    opacity: 0.7;
  }
}

.dropDownBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 0.625rem;
  padding: 4px 0px;
  p {
    display: inline-block;
    margin: 0px;
    flex-basis: 80%;
    text-align: start;
  }
}
/** commented for setting the topBar in user Dashboard */
.dropdown-menu {
  // width: 100% !important;
}
.dropdown-item {
  background-color: $white !important;
}

.dropdown-item:active {
  color: initial !important;
}
.dropdown-toggle::after {
  display: none !important; // Hide the default down arrow
}
#dropdown-custom {
  background-color: transparent;
  border: none;
  outline: none;
}
#dropdown-custom span {
  color: $black;
  font-family: Graphik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0px 4px;
  display: inline-block;
}
#phoneInputBox {
  box-shadow: none;
  border: 0.5px solid;
  border-color: $lightGrey;
  border-radius: 6px;
}
#phoneInputField {
  border: none;
}
.employeeCustom {
  position: absolute;
  padding-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
  max-height: 800px;
  border-radius: 0.625rem;
  border: 0.5px solid $lightGrey;
  background: $white;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 0px 20px 10px;
  .content {
    text-align: center;
    max-width: 60% !important;
    @media only screen and (max-width: 767px) {
      font-size: 0.9rem;
      width: 100% !important;
    }
  }
  .roomList {
    min-height: 400 !important;
    max-height: 800px !important;
  }
  @media only screen and (max-width: 792px) {
    padding: 10px;
    min-height: 400px;
    max-height: 600px;
    position: static;
    .roomList {
      min-height: 300px;
      max-height: 400px !important;
    }
  }
}
// css for ButtonBox Component
.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
  max-height: 400px;
  border-radius: 0.625rem;
  border: 0.5px solid $lightGrey;
  background: $white;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 0px 20px 10px;
  @media only screen and (max-width: 767px) {
    padding: 10px;
    min-height: 200px;
    max-height: 400px;
  }
  .content {
    text-align: center;
    max-width: 60% !important;
    @media only screen and (max-width: 767px) {
      font-size: 0.9rem;
      width: 100% !important;
    }
  }
}
#label {
  font-weight: $fontExtraBold;
}

.serviceSelect__placeholder {
  color: $lightGrey !important;
  font-size: 14px;
}

.colorsFeild {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  // border: 0.5px solid #a9b0bf;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//For firefox :

input[type="number"] {
  -moz-appearance: textfield;
}

#newPassword,
#password,
#confirmPassword,
#changePassword,
#newPassword {
  padding-right: 34px;
}
.form-check {
  label {
    font-size: $fontSM;
    color: $secondary;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em;
    border: 0.5px solid $lightGrey;
    padding: 8px;
  }
}
.changeError {
  color: $danger !important;
  font-size: $fontXSM !important;
}
