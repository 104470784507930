@import "../../scss/var";

.DashboardLayout {
  --headerHeight: 70px;

  background-color: #f7f6f9;
  --sidebarWidth: 230px;
  @include max(767) {
    --sidebarWidth: 200px;
  }
  &:global(.sidebarClose) {
    padding: 0;
    .DashboardPage {
      padding: 0;
    }
    .DashboardSidebar {
      transform: translate(-100%);
      box-shadow: none;
    }
  }
}
.DashboardPage {
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  background-color: #fff;
  transition: 0.15s linear;
  @include min(992) {
    padding-left: var(--sidebarWidth);
  }
}
.DashboardSidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // box-shadow: 4px 0px 19px -3px rgba(0, 0, 0, 0.5);
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  background-color: $white;
  max-width: var(--sidebarWidth);
  width: var(--sidebarWidth);
  z-index: 999;
  transition: 0.15s linear;
}
.overlay {
  @include max(991) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(black, 0.5);
    width: 100%;
    z-index: 998;
    display: block;
    &.hide {
      display: none;
    }
  }
}
.DashboardBody {
  height: 100vh;
  overflow-y: auto;
}
.DashboardBody_content {
  padding: 1rem 2rem 0;
  margin-bottom: 30px !important;
  @include max(767) {
    padding: 1rem 1rem 0;
  }
}
