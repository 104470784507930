@import "../../../src//scss/var";

.prop_label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #0a0e18;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
}
.dropdownToggle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 13px !important;
  width: 100% !important;
  border-radius: 0.375rem !important;
  border: 0.5px solid $lightGrey !important;
  background: $bgColor !important;
}
.dropdownItem {
  padding-left: 10px !important;
}

.dropdownMenu {
  padding: 10px 0px !important;
  width: 94% !important;
  max-height: 300px;
  overflow: auto;
  @media (max-width: 1200px) {
    width: 98% !important;
  }
}
.selectedItems {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
  p {
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    background: #dddfe3;
    display: inline-flex;
    min-height: max-content;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 1;
    color: $black;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 0px;
  }
}
.error {
  color: #d22b2b !important;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
}
