@import "../../../scss/var";

.login {
  max-width: 440px;
  margin: auto;
}
.login_ByGoogle {
  flex-basis: 420px;
  .formTitle {
    margin: 1rem 0 2rem;
    img {
      width: 45px;
    }
    h2 {
      text-align: center;
      font-size: $fontLG;
    }
    p {
      font-weight: $fontNormal;
    }
  }
}
.formFields {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.formOptions_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global(.form-check-label) {
    font-weight: 300;
    font-size: $fontSM;
  }
  a {
    color: $black;
    text-decoration: none;
    font-size: $fontSM;

    font-weight: $fontBold;
    line-height: 150%; /* 21px */
    padding-bottom: 12px;
  }
}
.createRegister {
  margin-top: 1.4rem;
  font-size: $fontSM;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
