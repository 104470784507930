@import "../../../scss/var";
.mainPassword {
  margin-top: 50px;
  .changePassword {
    text-align: center;
    img {
      position: relative;
    }
  }
  .chng {
    padding-right: 140px;

    @media (max-width: 1440px) {
      padding-right: 75px;
    }
    @media (max-width: 1100px) {
      padding-right: 20px;
    }
    @media (max-width: 768px) {
      padding-right: 0px;
    }
  }
  .passwordText {
    border-radius: 10px;
    background: #efefef;
    padding: 30px;
    h4 {
      color: $black;
      font-size: $fontLG;
      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
      padding-bottom: 12px;
    }
    .formchange {
      margin-bottom: 15px;
      :global(.input_wrap) {
        input {
          min-height: 50px;
          border-radius: 6px;
          border: 0.5px solid $lightGrey;
          background: $inputBgColor;
          margin-top: 4px;
        }
      }
    }
  }
}
