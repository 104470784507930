@import "../../../../scss/var";
// @mixin cardStyle {
//   background-color: #fff;
//   padding: 1rem 1.5rem;
//   border-radius: 8px;
//   color: $titleColor;
//   box-shadow: 0 0 18px -6px rgba(black, 0.25);
//   cursor: pointer;
//   transition: 0.15s linear;
//   &:hover {
//     box-shadow: 0px 0px 26px -6px rgba(black, 0.3);
//   }
// }
.dash_page {
  padding: 1rem 0;
}
// a {
//   text-decoration: none;
.dash_card {
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  height: 180px;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: #525252;
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background-color: $white;
  //   @include box;import { ReactComponent as Icon5 } from "../../../assests/icon5.svg";

  .dash_cardImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    svg {
      font-size: 1.5em;
      color: $secondary;
    }
  }
  h5 {
    color: $secondary;
    font-size: 16px;

    font-weight: $fontNormal;
    line-height: 150%; /* 24px */
  }
  h3 {
    color: $black;
    font-weight: $fontBold;
    line-height: 150%; /* 42px */
    margin-bottom: 0px;
    span {
      svg {
        fill: #676d7a;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
        top: -2px;
      }
    }
  }
}
.dash_cardLg {
  min-height: 550px;
  //   @include box;
}
// }
.dashboardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 25px;
    h2 {
      font-size: 22px;
    }
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 20px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
  .dashboardHeadings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: start;
    }
    .filters {
      display: flex;
      align-items: center;
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        color: $secondary;
        text-align: center;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
    }

    button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;

      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    margin-bottom: 0;
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        .statusDiv {
          display: flex;
          align-items: center;
          span {
            width: 8px;
            height: 8px;
            margin-right: 12px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: 21px;
          }
        }
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}
tr {
  &:last-child {
    td {
      border-bottom: 0;
    }
  }
}

.dash_cardView {
  flex-direction: row !important;
  height: auto !important;
  h5 {
    margin-bottom: 0;
  }
}

.search {
  position: relative;
  display: flex;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
    button {
      margin-left: 0 !important;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  svg {
    position: absolute;
    top: 13px;
    left: 10px;
    @media only screen and (max-width: 767px) {
      top: 23px;
    }
  }
  input {
    padding-left: 30px;
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 40px;
    background: $white;
    &::placeholder {
      color: $lightGrey;
      font-size: 12px;

      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    font-weight: 700;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}

.orgnaizationDetails {
  .search {
    width: 50%;
    flex-wrap: wrap;
    justify-content: end;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
      align-items: center;
    }
    button {
      margin-bottom: 10px;
      border: 0.5px solid #a9b0bf;
      height: 40px;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      span {
        svg {
          position: inherit;
          margin-right: 2px;
          width: 14px;
          height: 14px;
        }
      }
    }
    a {
      @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.searchQr {
  button {
    margin-bottom: 10px;
    span {
      svg {
        position: inherit;
        margin-right: 5px;
      }
    }
  }
}

.LogoName {
  .LogoBg {
    border-radius: 10px;
    border: 0.5px solid #a9b0bf;
    padding: 15px;
    background: $bgColor;
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}

.rightSides {
  position: relative;
  @media only screen and (max-width: 767px) {
    margin-top: 25px;
  }
  .rightName {
    h6 {
      color: #676d7a;
      font-size: 16px;

      font-weight: 400;
      line-height: 150%; /* 24px */
      display: flex;
      align-items: center;
      span {
        width: 8px;
        height: 8px;
        background-color: #34c759;
        margin-left: 15px;
        margin-right: 5px;
        display: block;
        border-radius: 50%;
      }
    }
    h2 {
      font-size: 28px;
      font-weight: 500;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * 5px);
    .cardsBox_outer {
      padding: 5px;
      min-width: 25%;
      flex-grow: 1;
    }
    .cardsBox {
      border-radius: 4px;
      border: 0.5px solid #a9b0bf;
      padding: 12px;
      background: #fff;
      // width: 24%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 0px;
      }
      h4 {
        color: $black;
        font-size: 14px;
        word-wrap: break-word;
        font-weight: 500;
        line-height: 150%; /* 21px */
        margin-bottom: 0;
      }
      .colorTheme {
        display: flex;
        justify-content: space-between;
        .colorLeft {
        }
        .colorRight {
          span {
            width: 30px;
            height: 30px;
            display: block;
            background-color: $black;
            border-radius: 6px;
          }
        }
        .fontColorBox {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.services {
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  p {
    color: $black;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    font-size: 16px;
    margin-bottom: 8px;
  }
  .dashboardTable {
    .catServ {
      border-radius: 4px;
      border: 0.5px solid $lightGrey;
      padding: 15px;
      background: $white;
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        li {
          width: 40%;
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
          .mainDev {
            h4 {
              color: $black;
              font-size: $fontSM;
              font-weight: $fontBold;
              line-height: 150%; /* 21px */
              margin-bottom: 0;
            }
            p {
              color: $secondary;
              font-size: $fontSM;
              font-weight: $fontNormal;
              line-height: 150%; /* 21px */
              margin-bottom: 0;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.servicesRooms {
  margin-top: -50px;
  @media only screen and (max-width: 767px) {
    margin-top: 0;
  }
}

.emptydiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 188px;
  h2 {
    color: $black;
    text-align: center;
    font-size: 16px;
    font-weight: $fontExtraBold;
    margin-top: 8px;
    line-height: 150%; /* 24px */
  }
  p {
    color: $secondary;
    text-align: center;
    font-size: 14px;
    line-height: 150%; /* 21px */
    margin-bottom: 0;
  }
}

.tableRemoveShadow {
  box-shadow: none;
}

.cardsWrap {
  @include flexContainer(calc(100% / 2), 11px, 22px, flex-start);
  .card {
    height: 100%;
    border: 0.5px solid $lightGrey;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 15px;
  }
  .amount {
    font-size: $fontXL;
    font-weight: $fontBold;
  }
  .title {
    color: $secondary;
  }
  @include max(1199) {
    .amount {
      font-size: 24px;
    }
  }
  @include max($lg) {
    --width: calc(100% / 2);
  }
  @include max($md) {
    --width: 100%;
  }
  margin-top: 25px;
  margin-bottom: 25px;
}
