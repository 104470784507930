.card {
  display: flex;
  border: 0.5px solid #a9b0bf;
  border-radius: 10px;
  padding: 12px;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 116px;
  .cardTitle {
    font-size: 16px;
    color: #676d7a;
    font-weight: 400;
    line-height: 24px;
  }
  .cardAmount {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    color: #0a0e18;
  }
}
