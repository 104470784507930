@import "../../../../src//scss/var";
.dash_page {
  padding: 1rem 0;
  --extraHeight: 300px;

  .search {
    position: relative;
    display: flex;
    gap: 10px;
    .searchField {
      margin-right: 10px;
      position: relative;
    }
    .crossIcon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background: #fff;
      padding-left: 8px;
      svg {
        color: #2549E8;
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      width: 100%;
      button {
        margin-left: 0 !important;
      }
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
    .searchIcon {
      position: absolute;
      top: 13px;
      z-index: 9;
      left: 10px;
      @media only screen and (max-width: 767px) {
        top: 23px;
      }
    }
    input {
      padding-left: 30px;
      border-radius: 6px;
      border: 0.5px solid $lightGrey;
      background: $white;
      background-color: $white !important;
      padding-right: 10px !important;
      &::placeholder {
        color: $lightGrey;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
      &:focus-visible {
        outline: none;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 10px;
      }
      &:global(.form-control) {
        min-height: 40px !important;
        color: $black !important;
        // padding-left: 30px !important;
      }
    }
  }
}
