@import "../../scss/var";

.payment_wrap {
  background-color: #e5e3ff;
  padding: 15px;
  border: 1px solid #635bff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .setup_left {
    display: flex;
    gap: 20px;
    align-items: center;
    .setup_content {
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #05111c;
        margin: 0px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #6a6d7c;
        margin: 0px;
        width: 90%;
      }
    }
  }

  .payment_content {
    content: "";
    border-radius: 10px;
    height: 70px;
    min-width: 70px;
    background-color: $white;
    position: relative;

    .innerContent {
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }
}
