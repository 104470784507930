@import "../../../scss/var";

.login {
  max-width: 440px;
  margin: auto;
}
.login_ByGoogle {
  flex-basis: 420px;
  .formTitle {
    margin: 1rem 0;
    img {
      width: 45px;
    }
    h2 {
      text-align: center;
      font-size: $fontLG;
    }
    p {
      text-align: center;
    }
  }
}
.formFields {
  text-align: left;

  label {
    text-align: left;
    font-size: $fontSM;
    font-weight: $fontBold;
    margin-bottom: 2px;
  }
  input {
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 50px;
    background: $inputBgColor;
    padding-left: 35px;
    &::placeholder {
      color: $lightGrey;
      font-size: $fontSM;

      font-weight: $fontNormal;
      line-height: 150%; /* 21px */
    }
  }
}
.formOptions_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global(.form-check-label) {
    font-weight: 300;
    font-size: $fontSM;
  }
}
.createRegister {
  margin-top: 1.4rem;
  font-size: $fontSM;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.goButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
