@import "./var";
@import "~bootstrap/scss/bootstrap";
@import "./common";
@import "./themeComponents";
@import "src/scss/fonts";

.App {
  .container {
    @include max($lg) {
      max-width: 100%;
    }
  }
  .commonBtn {
    margin-top: 12px;
    border-radius: 6px;
    background: $primary;
    border: 1px solid $primary;
    margin-left: auto;
    height: 40px;
    width: auto;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .commonBtnTransparnt {
    background-color: transparent;
    color: $black;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
    &:hover {
      background-color: transparent;
      color: $black;
    }
    .loader2 {
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        height: 20px;
        width: 20px;
      }
    }
    &.loading {
      color: transparent !important;
    }
    &.disabled {
      opacity: 0.9;
    }
  }

  .commonBtn:hover {
    background: $primary !important;
    border: 1px solid $primary !important;
  }
}

.mnuDrop.dropdown-menu {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15)) !important;
  border: none !important;
  border-radius: 12px;
  z-index: 99 !important;
}
.footerModalBtn {
  border-radius: 6px;
  background: $primary !important;
  border: 1px solid $primary !important;
  margin-left: auto;
  height: 40px;
  width: auto;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-filter__control {
  border-radius: 6px !important;
  border: 0.5px solid $lightGrey;
  background: $bgColor !important;
  height: 30px;
  &:hover {
    border: 0.5px solid $lightGrey !important;
  }
}
.react-filter__placeholder,
.react-filter__single-value {
  color: $secondary !important;
  font-size: 12px;

  font-weight: $fontNormal;
  line-height: 150%; /* 18px */
}

span.react-filter__indicator-separator {
  background: transparent;
}
.react-filter__indicator svg {
  fill: $secondary;
}

.react-filter__menu,
.react-filter__menu-list {
  min-width: 160px;
}
.react-filter__menu {
  fill: $white;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  border: none;
  border-radius: 10px !important;
  box-shadow: none !important;
}
.react-filter__option {
  color: $secondary !important;
  font-size: $fontSM !important;

  font-weight: $fontNormal;
  line-height: 150%; /* 21px */
  border-bottom: 0.5px solid $lightGrey;
  padding: 12px !important;
}

.react-filter__option:last-child {
  border-bottom: 0;
}

.react-filter__option--is-focused,
.react-filter__option--is-selected {
  color: $black !important;
  background-color: #676d7a26 !important;
}

.modalranges .modal-dialog {
  max-width: 700px !important;
}
.modalranges .modal-title {
  color: $secondary !important;
  font-size: $fontLG;
  font-weight: $fontBold;
  line-height: normal;
  svg {
    margin: 0 8px 0 6px;
    position: relative;
    top: 0px;
    fill: $black;
  }
}

.commonBtn.btn.btn-secondary {
  height: 40px;
}

.fade.modal-backdrop {
  background: rgba(251, 251, 251, 0.6);
  backdrop-filter: blur(4px);
  opacity: 1 !important;
}
.modal-content {
  border-radius: 10px !important;
  background: #fff;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.15);
  border: none !important;
}

.serviceSelect__control {
  // height: 50px;
  border: 0.5px solid $lightGrey !important;
  background: $inputBgColor !important;
}

.serviceSelect__value-container {
  height: 50px;
}

.serviceSelect__input-container {
  height: 50px;
  margin: 0 !important;
}
span.serviceSelect__indicator-separator {
  background: transparent;
}

.tooltip {
  left: -10px !important;
  .tooltip-inner {
    border-radius: 3px;
    border: 0.5px solid $lightGrey;
    background: #ededed !important;
    color: $secondary;
    min-height: 2rem;
    max-width: max-content;
  }
}

.tooltip-arrow {
  left: 10px !important;
}
.tooltip-arrow::before {
  border: 0 !important;
}
.tooltip-arrow::after {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  background: #ededed;
  border: 1px solid $lightGrey;
  bottom: 2px;
  transform: rotate(45deg);
  border-top: 0;
  border-left: 0;
  left: 2px;
}
.serviceSelect__menu-list {
  fill: #fff;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
}
.serviceSelect__option {
  color: $secondary;
  font-family: Graphik;
  font-size: 14px;

  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px solid #a9b0bf;
}
.serviceSelect__option:last-child {
  border: none;
}
// tr {
//   &:last-child {
//     td {
//       border-bottom: 0 !important ;
//     }
//   }
// }
tr {
  td {
    vertical-align: middle;
  }
}

.dropdown {
  position: static;
  .dropdown-toggle {
    padding: 0;
  }
}
.serviceSelect__option--is-focused,
.serviceSelect__option--is-selected {
  color: $black !important;
  background-color: #676d7a26 !important;
}

.react-partner__control {
  border-radius: 6px !important;
  border: 0.5px solid #a9b0bf !important;
  background-color: $bgColor !important;
  height: 44px !important;
}
.react-partner__option--is-selected,
.react-partner__option--is-focused {
  color: $black !important;
  background-color: #676d7a26 !important;
}
span.react-partner__indicator-separator {
  background: transparent;
}

.dropdown-menu.show.dropdown-menu-end {
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
  border: none;
  z-index: auto;
}
.dropdown-menu.show {
  min-width: 100%;
}
.modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
  button {
    height: 40px;
    width: 114px;
  }
}

.paginations {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
  label {
    color: $secondary;
    margin-bottom: 0;
    text-align: center;
    font-size: 12px;

    font-weight: 400;
    line-height: 150%; /* 18px */
    select {
      border-radius: 6px;
      border: 0.5px solid #a9b0bf;
      margin-left: 12px;
      background: $bgColor;
      color: $secondary;
      text-align: center;
      font-size: 12px;
      font-family: system-ui !important;
      font-weight: 400;
      height: 30px;
      line-height: 150%; /* 18px */
      width: 40px;
    }
  }
  // .pagination {
  //   border: none;
  //   margin-bottom: 0px;
  //   li {
  //     border: none;
  //     a {
  //       border: none;
  //       color: $secondary;
  //       font-size: 12px;

  //       font-weight: 400;
  //       line-height: 150%;
  //       background-color: transparent !important;
  //       box-shadow: none;
  //       outline: none;
  //     }
  //   }
  //   .active {
  //     .page-link {
  //       color: var(--Orange, #2549E8) !important;
  //       font-size: 12px;

  //       font-weight: 600 !important;
  //       line-height: 150%; /* 18px */
  //       text-decoration-line: underline;
  //       background-color: transparent !important;
  //     }
  //   }
  //   .disabled {
  //     .page-link {
  //       background-color: transparent !important;
  //       border: none;
  //       padding: 0.375rem !important;
  //       height: 30px;
  //       line-height: 17px;
  //     }
  //   }
  // }
}

.react-tel-input input.form-control {
  border-radius: 6px;
  border: 0.5px solid #a9b0bf !important;
  height: 50px !important;
  background: $inputBgColor !important;
  width: 100% !important;
  box-shadow: none;
}
.react-tel-input .flag-dropdown {
  border-right: 0 !important;
}

.colorBg {
  background: $bgColor !important;
  position: relative;
  .loader {
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
  }
}

// Link text styling
.linkText {
  width: fit-content;
  color: $black;
  font-weight: 500;
  font-size: $fontSM;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  &.primary {
    color: $primary;
    text-decoration: underline;
  }
}

.table-responsive {
  --extraHeight: 0px;
  max-height: calc(100vh - (var(--headerHeight) + var(--extraHeight)));
}

.react_select__menu .react_select__option {
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  border-bottom: 0.1px solid $lightGrey;
  padding: 12px 15px;
  // background-color: #fff;
  // border-radius: 10px;
}
.react_select__menu .react_select__option:last-child {
  border-bottom: 0;
}

/**** Calender custom css ************/
.rdrCalendarWrapper {
  position: relative;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateDisplayWrapper {
  background-color: transparent;
  position: absolute;
  width: 100%;
  .rdrDateDisplayItem {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $black;
    font-size: 16px;
    input {
      color: $black;
    }
  }
}
span.rdrMonthAndYearPickers {
  display: none;
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrNextPrevButton {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  i {
    display: none;
  }
  :hover {
    background: transparent;
  }
}
.rdrNextPrevButton:hover {
  background-color: transparent !important;
}
.rdrPprevButton {
  background-image: url("../assests/chevron-left.svg") !important;
  :hover {
    background: url("../assests/chevron-left.svg") !important;
  }
}

.rdrNextButton {
  background-image: url("../assests/chevron-right.svg") !important  ;
  :hover {
    background: url("../assests/chevron-left.svg") !important;
  }
}

.rdrDayNumber {
  height: 40px;
  span {
    color: $black !important;
    font-size: 14px;
  }
}

span.rdrInRange {
  border-radius: 6px;
  background: #ededed;
  height: 40px;
}

span.rdrStartEdge,
span.rdrEndEdge {
  border-radius: 6px;
  background: var(--Orange, #2549e8);
  color: #fff !important;
  font-weight: 600;
  height: 40px;
}
.rdrDayToday .rdrDayNumber span:after {
  height: 0;
}

button.rdrDay {
  border-left: 5px solid #fff;
  height: 50px;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

// .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
// .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: #fff !important;
  font-weight: 400 !important;
}

.rdrDay,
.rdrDayHovered {
  border-color: transparent;
  span {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

span.rdrMonthAndYearPickers {
  color: $black;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  flex: inherit;
  justify-content: space-evenly;
}
.rdrMonthAndYearPickers select {
  background: none !important;
  color: $black;
}

.rdrMonthName {
  position: absolute;
  top: 1%;
  width: 200px;
  text-align: end;
  font-weight: 500;
  font-size: 18px;
  color: $black;
  // left: 14%;
}
.rdrDateDisplayWrapper .rdrDateDisplayItem input {
  display: none;
}
span.rdrWeekDay {
  color: $secondary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.rdrMonthAndYearWrapper {
  margin-bottom: 15px;
  padding-top: 0;
  z-index: 99;
}
.rdrWeekDays {
  margin-bottom: 10px;
}

.crossIcon {
  left: inherit;
  right: 24px;
  svg {
    color: #2549e8;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1366px) {
  // .custom-modal-css {
  //   align-items: initial !important;

  // }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $lightGrey;
  border-radius: 10px;
}

.react_select__single-value {
  color: $secondary !important;
}

.emails::-webkit-scrollbar {
  width: 8px !important;
}

.modal-header {
  display: flex !important;
  justify-content: space-between !important;
}
