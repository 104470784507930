@import "../../scss/var";

.payment_wrap {
  background: #ffc6be;
  padding: 15px;
  border: 1px solid #ff4026;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .setup_left {
    display: flex;
    gap: 20px;
    align-items: center;
    .setup_content {
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #05111c;
        margin: 0px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: black;
        margin: 0px;
        width: 80%;
      }
    }
  }

  .payment_content {
    content: "";
    border-radius: 10px;
    height: 70px;
    min-width: 70px;
    background-color: $white;
    position: relative;

    .innerContent {
      position: absolute;
      top: 18px;
      left: 18px;
    }
  }
  .btn_color {
    background: #ff4026;
    border: none;
    white-space: nowrap;
    &:hover {
      background: #ff4026;
    }
  }
}
