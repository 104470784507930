@import "../../../../../scss/var";
// @mixin cardStyle {
//   background-color: #fff;
//   padding: 1rem 1.5rem;
//   border-radius: 8px;
//   color: $titleColor;
//   box-shadow: 0 0 18px -6px rgba(black, 0.25);
//   cursor: pointer;
//   transition: 0.15s linear;
//   &:hover {
//     box-shadow: 0px 0px 26px -6px rgba(black, 0.3);
//   }
// }
.dash_page {
  padding: 1rem 0;

  a {
    text-decoration: none;
    .dash_card {
      display: flex;
      gap: 0.6rem;
      flex-direction: column;
      height: 180px;
      border-radius: 12px;
      padding: 1rem 1.5rem;
      color: #525252;
      border-radius: 10px;
      border: 0.5px solid $lightGrey;
      background-color: $white;
      //   @include box;import { ReactComponent as Icon5 } from "../../../assests/icon5.svg";

      .dash_cardImg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        svg {
          font-size: 1.5em;
          color: $secondary;
        }
      }
      h5 {
        font-size: 16px;
        font-weight: $fontNormal;
        line-height: 150%; /* 24px */
      }
      h3 {
        color: $black;
        font-weight: $fontBold;
        line-height: 150%; /* 42px */
        margin-bottom: 0px;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: $fontNormal;
      line-height: 150%; /* 24px */
    }
    h3 {
      color: $black;
      font-weight: $fontBold;
      line-height: 150%; /* 42px */
      margin-bottom: 0px;
    }
  }
  .dash_cardLg {
    min-height: 550px;
    //   @include box;
  }
}
.dashboardHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 25px;
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 20px;

  .dashboardHeadings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: start;
    }
    .filters {
      display: flex;
      align-items: center;
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        color: $secondary;
        text-align: center;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
    }

    button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;

      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.dash_cardView {
  flex-direction: row !important;
  height: auto !important;
  h5 {
    margin-bottom: 0;
  }
}

.search {
  position: relative;
  svg {
    position: absolute;
    top: 13px;
    left: 10px;
    @media only screen and (max-width: 767px) {
      top: 23px;
    }
  }
  input {
    padding-left: 30px;
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 40px;
    background: $white;
    &::placeholder {
      color: $lightGrey;
      font-size: 12px;

      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    padding: 0;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    svg {
      margin-right: 6px;
    }
  }

  &:global(.dropdown-menu) {
    fill: $white;
    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.15));
    border: none;
    border-radius: 8px;
    min-width: 12rem;
  }
}

.dashboardHeadings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: start;
  }
  @media only screen and (max-width: 480px) {
    justify-content: center;
  }
  .filters {
    display: flex;
    align-items: center;
    margin-left: 5px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    label {
      color: $secondary;
      text-align: center;
      font-size: 12px;

      font-weight: $fontNormal;
      line-height: 150%; /* 18px */
    }
  }

  button {
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

.cardsWrap {
  @include flexContainer(calc(100% / 2), 11px, 22px, flex-start);
  .card {
    height: 100%;
    border: 0.5px solid $lightGrey;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 15px;
  }
  .amount {
    font-size: $fontXL;
    font-weight: $fontBold;
  }
  .title {
    color: $secondary;
  }
  @include max(1199) {
    .amount {
      font-size: 24px;
    }
  }
  @include max($lg) {
    --width: calc(100% / 2);
  }
  @include max($md) {
    --width: 100%;
  }
  margin-top: 25px;
  margin-bottom: 25px;
}

.tipsWrap {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  @include max(1060) {
    justify-content: flex-start;
  }
}
