@import "../../../scss/var";

.dash_page {
  padding: 1rem 0;

  .setting_card {
    background-color: $grey;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      color: $black;
      font-size: 16px;
      font-weight: $fontBold;
    }
    p {
      font-size: $fontSM;
      font-weight: $fontNormal;
      color: $secondary;
      margin-bottom: 0px;
    }
    .toggleButton {
      .form_check {
      }
      span {
        position: absolute;
        top: 34%;
        color: $black;
        font-size: $fontSM;
        font-weight: $fontBold;
      }
      .inputGroup {
        /* Add box-shadow here */
      }
    }
  }
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

// .form-switch .form-check-input:checked {

// }
.form-switch .form-check-input {
  width: 76px !important;
  // background-color: chartreuse !important;
  height: 40px;
  background-image: url("../../../assests/Knob.svg");
  background-size: auto;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}
.form-switch .form-check-input:checked {
  background-image: url("../../../assests/KnobOff.svg");
  background-size: auto;
  border-color: transparent !important;
  background-color: #fff !important;
}
input {
  cursor: pointer;
}
