@import "../../../scss/var";

.dash_page {
  padding: 1rem 0;
  --extraHeight: 300px;
  .search {
    position: relative;
    display: flex;
    gap: 10px;
    .searchField {
      // margin-right: 10px;
      position: relative;
    }
    .crossIcon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background: #fff;
      padding-left: 8px;
      svg {
        color: #2549e8;
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      width: 100%;
      button {
        margin-left: 0 !important;
      }
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
    .searchIcon {
      position: absolute;
      top: 13px;
      z-index: 9;
      left: 10px;
      @media only screen and (max-width: 767px) {
        top: 23px;
      }
    }
    input {
      padding-left: 30px;
      border-radius: 6px;
      border: 0.5px solid $lightGrey;
      background: $white;
      background-color: $white !important;
      padding-right: 10px !important;
      &::placeholder {
        color: $lightGrey;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
      &:focus-visible {
        outline: none;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 10px;
      }
      &:global(.form-control) {
        min-height: 40px !important;
        color: $black !important;
        // padding-left: 30px !important;
      }
    }
  }
}

.dashboardTable {
  border-radius: 10px;
  border: 0.5px solid $lightGrey;
  background: var(--White, $white);
  padding: 8px 20px;

  .dashboardHeadings {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: start;
    }
    .filters {
      display: flex;
      align-items: center;
      margin-left: 5px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      label {
        color: $secondary;
        text-align: center;
        font-size: 12px;

        font-weight: $fontNormal;
        line-height: 150%; /* 18px */
      }
    }

    button {
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    h3 {
      margin: 0;
      color: $black;
      font-size: $fontLG;

      font-weight: $fontExtraBold;
      line-height: 150%; /* 30px */
    }
  }
  table {
    thead {
      th {
        color: $black;
        font-size: $fontSM;

        font-weight: $fontBold;
        line-height: 150%; /* 21px */
        padding: 1rem;
      }
    }
    tbody {
      td {
        color: $secondary;
        font-size: $fontSM;

        font-weight: $fontNormal;
        line-height: 150%; /* 21px */
        padding: 1rem;
        .statusDiv {
          display: flex;
          align-items: center;
          span {
            width: 8px;
            height: 8px;
            margin-right: 12px;
            display: block;
            border-radius: 50%;
            left: 0;
            top: 21px;
          }
        }
        svg {
          cursor: pointer;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.red {
  background-color: #34c759;
}

.green {
  background-color: #ff4218;
}

.dropDots {
  button {
    background-color: transparent !important;
    border: none;
    transform: rotate(90deg);
    color: $secondary;
    font-size: 20px;
    &:after {
      border: none;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:global(.show) {
    background-color: transparent !important;
  }
  a {
    padding: 10px 10px;
    border-bottom: 0.5px solid $lightGrey;

    color: $secondary;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    &:last-child {
      border-bottom: 0;
      padding-bottom: 5px;
    }
    &:focus-visible {
      outline: none;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
}

.textStyle {
  text-align: center;
}
