@import "../../scss/var";

.mainWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-weight: $fontExtraBold;
  font-size: $fontXL;
}
