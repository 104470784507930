@import "../../scss/var";

.logo {
  padding: 0 1rem 1rem;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  span {
    font-weight: $fontExtraBold;
    font-size: 1rem;
    color: #ffffffe3;
  }
  img {
    max-height: 34px;
    width: 147px;
  }
}
.sidebarWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.sidebarList {
  padding: 1.4rem 0rem;
  ul {
    margin-top: 1rem;
    padding: 0;
    font-size: $fontSM;
    a {
      display: block;
      cursor: pointer;
      position: relative;
      transition: 0.15s ease-in-out;
      margin-bottom: 1rem;
      transition: 0.2s linear;
      text-decoration: none;
      padding: 7px 5px 7px 5px;
      border-left: 4px solid transparent;
      &:global(.active) {
        border-color: #2a2a2a;
        color: $primary;
        background: linear-gradient(90deg, $skyBlue 0%, #fff 100%);
        border-left: 4px solid $primary;
        border-radius: 2px 0px 0px 2px;
        li {
          color: inherit;
        }
      }
    }
    li {
      color: $secondary;
      font-weight: $fontNormal;
      cursor: pointer;
      padding: 4px 1rem;
      display: flex;
      gap: 14px;
      align-items: center;
      svg {
        opacity: 0.9;
        font-size: 1.2em;
      }
    }
  }
}
