@import "../../../src/scss/var";

.mainWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: $fontXL;
  font-weight: $fontBold;
  h3 {
    color: $black;
    font-family: Graphik;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  p {
    color: $secondary;
    text-align: center;
    font-family: Graphik;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
