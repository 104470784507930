@import "../../../../src/scss/var";
.dash_page {
  padding: 1rem 0;
}

.orgLogoWrap {
  display: flex;
  align-items: center;
  gap: 25px;
  // position: relative;
  .orgLogo {
    content: "";
    border-radius: 50%;
    height: 120px;
    width: 120px;
    background-color: $bgColor;
    border: 0.5px solid $grey;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .innerContent {
      img {
        // height: 100%;
        width: 100%;
        border-radius: 50%;
        height: 122px;
        width: 122px;
        object-fit: contain;
        // border-radius: 50%;
      }
    }
    .addIcon {
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
}
.hidden {
  display: none;
}
.remove_btn {
  background: white !important;
  border: 1px solid #ff0000 !important;
  color: #ff0000 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
  padding: 2px !important;
  min-height: 32px !important;
  // &:hover {
  //   background-color: #ff00004a !important;
  //   color: #ff0000 !important;
  //   border: none !important;
  // }
}
