@import "../../../scss/var";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 1.5rem;
  }
  .modalContent {
    color: #222;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btnRight {
      background: #00a3ff;
      border-color: #00a3ff;
    }
    .btnLeft {
      color: #00a3ff;
      border-color: #00a3ff;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .modalTitle {
    text-align: center;

    h4 {
      text-align: center;
      font-size: $fontSM;
      font-weight: $fontNormal;
      line-height: 150%; /* 21px */
      margin-bottom: 0;
    }
    h2 {
      margin-top: 1rem;
      text-align: center;
      font-family: Graphik;
      font-size: 1rem;
      font-style: normal;
      font-weight: $fontExtraBold;
      line-height: 150%;
    }
  }
}

.confirmationModal {
  :global(.modal-dialog) {
    width: 340px;
    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
}

.modalContent {
  :global(.modal-footer) {
    justify-content: center;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    button {
      padding: 0 25px;
    }
  }
}
