@import "../../scss/var";
.breadcrumb {
  :global {
    .breadcrumb-item {
      & + .breadcrumb-item {
        &::before {
          content: ">";
          font-size: larger;
        }
      }
    }
  }
  li {
    a {
      text-transform: capitalize;
      color: $secondary;
      font-family: Graphik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
    }
  }
  ol{
    margin-bottom: 0px;
  }
}
