@import "../../scss/var";

.modal_wrapper {
  // width: 100%;
  max-width: 100%;
  background-color: transparent;
  :global {
    .modal-dialog {
      max-width: none;
      margin: 0;
      max-height: 100%;
    }
    .modal-content {
      display: block;
      flex-direction: row;
      display: flex;
      justify-content: center;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
}
.modalLayout {
  @include modalBox;
  margin: 2rem 0;
  :global {
    .btn-close {
      opacity: 1;
    }
  }
}

.addDropOrgnaization {
  overflow: inherit !important;
}

.addStepTwo {
  max-width: 400px !important;
}
