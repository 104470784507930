@import "../../../scss/var";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 1.5rem;
  }
  .modalContent {
    color: $brown;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btnRight {
      background: $lightBlue;
      border-color: $lightBlue;
    }
    .btnLeft {
      color: $lightBlue;
      border-color: $lightBlue;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .modalTitle {
    text-align: center;
    .icn {
      margin-bottom: 6px;
      font-size: 2.5rem;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      background-color: rgba($red, 0.1);
      color: $red;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      margin-bottom: 0;
    }
  }
}

:global(.modal-title) {
  color: $black !important;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 150%;
}
:global(.modal-footer) {
  justify-content: center;
  border: none;
  margin-top: 10px;
  button {
    padding: 0 25px;
  }
}

.addCateg {
  position: relative;
  label {
    color: $black;
    font-size: 14px;

    font-weight: 500;
    line-height: 150%; /* 21px */
  }
  .uploadInput {
    min-height: 50px;
    border: 0.5px solid $lightGrey;
    border-radius: 6px;
    background-color: $inputBgColor;
    display: block;
    input {
      display: none;
    }
    h6 {
      font-size: 14px;
      padding: 0px 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
      white-space: nowrap;
      height: 50px;
      margin: 0;
      line-height: 50px;
    }
  }
  input {
    border-radius: 6px;
    border: 0.5px solid $lightGrey;
    height: 50px;
    background: $inputBgColor;
    min-width: 67%;
    padding: 12px;
    &::placeholder {
      color: $lightGrey;
      font-size: 14px;

      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .btnBrowse {
    color: #fff;
    right: 10px;
    position: absolute;
    top: 34px;
    padding: 0 12px !important;
    height: 30px;
  }
}

.addEditModal {
  :global(.modal-dialog) {
    width: 340px;
    @media only screen and (max-width: 480px) {
      width: auto;
    }
  }
}

.error {
  position: absolute;
  color: $danger;
  right: 0;
  font-size: $fontXSM;
  top: 100%;
  font-weight: $fontNormal;
}

.butonSteps {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 25px;
  button {
    margin: 0 !important;
    color: #fff;
    padding: 0 35px;
  }
}

.steps {
  ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    li {
      .numberLabel {
        text-align: center;
        position: relative;
        img {
          position: absolute;
          right: -53px;
          top: 25px;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
        h4 {
          text-align: center;
          border: 1px solid $lightGrey;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 auto 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
        h5 {
          color: $secondary;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }
    }
    .activeStep {
      h5 {
        color: $black !important;
      }
      h4 {
        border-color: $black !important;
        color: $black !important;
      }
    }
  }
}

.copyUrl {
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      border: 0.5px solid $lightGrey;
      padding: 12px;
      margin-bottom: 15px;
      background: #fff;
      @media only screen and (max-width: 480px) {
        flex-direction: column;
        align-items: start;
      }
      .numberLabel {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 480px) {
          margin-bottom: 10px;
        }
        h5 {
          color: $black;
          font-size: 16px;

          font-weight: 500;
          line-height: 150%; /* 24px */
          margin-left: 12px;
          margin-bottom: 0;
        }
        h4 {
          color: $black;
          font-size: 14px;

          font-weight: 500;
          line-height: 150%; /* 21px */
          margin-bottom: 0;
          margin-right: 10px;
        }
        button {
          border-radius: 6px;
          border: 0.5px solid $lightGrey;
          height: 40px;
          padding: 0 10px;
          background: $bgColor;
          color: $black;
          font-size: 14px;

          font-weight: 500;
          line-height: 150%; /* 21px */
        }
      }
    }
  }
}

.addCategBox {
  border: 0.5px solid $lightGrey;
  background: #fff;
  width: 69.767px;
  min-height: 69.767px;
  border-radius: 10.465px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .closeIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    border: 1px solid $lightGrey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: $inputBgColor;
    cursor: pointer;
    svg {
      color: #676d7a;
      fill: #676d7a;
    }
  }
}

// .stepsTwos {
//   ul {
//     width: 100% !important;
//     li {
//       width: 33%;
//       .numberLabel {
//         img {
//           right: -24px;
//         }
//       }
//     }
//   }
// }

.DollorSign {
  position: relative;
  span {
    color: $black;
    font-size: 14px;

    font-weight: 600;
    line-height: 150%; /* 21px */
    position: absolute;
    top: 15px;
    left: 15px;
  }
  input {
    padding-left: 30px;
  }
}

.fontColorss {
  border-radius: 6px;
  border: 0.5px solid $lightGrey;
  height: 50px;
  background: $inputBgColor;
  width: 100%;
  padding: 12px;
  p {
    color: $black;
    font-size: 14px;
    line-height: 150%; /* 21px */
  }
}

.customCountryDropdown {
  /* Define your custom styles here */
  position: absolute;
  top: 0; /* Adjust as needed */
  left: 0; /* Adjust as needed */
  z-index: 1000; /* Ensure it's on top of other elements */
}

.placeColor {
  color: $lightGrey;
  font-size: 14px;
  font-weight: 400;
}
.butonSteps {
  display: flex;
  gap: 20px;
  justify-content: center;
  button {
    margin: 0 !important;
    color: #fff;
    padding: 0 35px;
  }
}

.inputHeading {
  color: $black;
  font-family: Graphik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.addServices {
  width: 100%;
  min-height: 3rem;
  border-radius: 0.375rem !important;
  border: 0.5px dashed $lightGrey !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  p {
    text-align: center;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px;
    margin: 0px;
  }
  .btn {
    text-align: center;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.3125rem */
  }
}

.dropdownToggle {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 13px !important;
  width: 100% !important;
  border-radius: 0.375rem !important;
  border: 0.5px solid $lightGrey !important;
  background: $bgColor !important;
  span {
    color: $lightGrey;
  }
}

.dropdownItem {
  padding: 0px !important;
}
.dropdownMenu {
  padding: 0px !important;
  max-height: 400px;
  overflow: auto;
  max-width: 100%;
}
.accordianBody {
  padding: 8px 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.selectedItems {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
  p {
    border-radius: 0.375rem;
    border: 0.5px solid $lightGrey;
    background: #dddfe3;
    display: inline-flex;
    min-height: 2rem;
    padding: 0rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
    color: $black;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    max-width: 100%;
    word-break: break-word;
    margin-bottom: 0px;
  }
}

.head1 {
  color: $black;
  font-family: Graphik;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 1.5rem */
}
.employeeList {
  padding: 12px;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  border: 0.5px solid $lightGrey;
  h4 {
    color: $black;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  span {
    color: $secondary;
    font-family: Graphik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .info {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }
}

.room {
  width: 100%;
  border: 0.5px solid $lightGrey;
  border-radius: 6px;
  padding: 12px;
  margin: 5px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // gap: 10px;
  font-weight: $fontBold;
  span {
    cursor: pointer;
    outline: none;
    border: none;
  }
  input {
    border: none;
    outline: none;
    font-weight: $fontBold;
  }
}
.roomList {
  padding-right: 0px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  h3 {
    font-weight: 500;
    margin: 0px;
    font-size: 14px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
  }
}
