@import "../../../../src/scss/var";
.dash_page {
  padding: 1rem 0;
}

.Container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .org p {
    font-size: 20px;
    font-weight: 600;
    color: $black;
  }
}
