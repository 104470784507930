@import "../../scss/var";

.topBar {
  // box-shadow: 0px 4px 10px -8px rgba(7, 7, 7, 0.35);
  height: var(--headerHeight);
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 4px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.15));
}
.topBar_search {
  display: flex;
  align-items: baseline;
  gap: 20px;
  input {
    // border-color: $lightGrey;
    min-height: 0;
    max-height: 30px;
    font-size: $fontSM;
  }
  button {
    border: none;
    font-size: 1.45rem;
    padding: 4px;
    background: none;
    line-height: 1;
  }
}
.topBar_checkTime {
  //   color: $titleColor;
  font-size: $fontSM;
  font-weight: $fontExtraBold;
}
.login_user {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  //   color: $titleColor;
  font-size: $fontSM;
  button {
    background-color: transparent !important;
    width: auto;
    border: none;
    color: $black !important;
    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 24px */
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: transparent;
      color: $black;
    }
    &:focus {
      background-color: transparent !important;
      color: $black !important;
    }
    &:active {
      background-color: transparent !important;
      color: $black !important;
    }
  }
  a {
    color: $secondary;

    font-size: $fontSM;

    font-weight: $fontNormal;
    line-height: 150%; /* 21px */
    svg {
      margin-right: 6px;
    }
  }
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: contain;
    box-shadow: 0px 0px 23px #c5c5c5; // border: 0.2px solid gray;
  }
  .arrowBottom {
    transform: rotate(-90deg);
    height: 24px;
    width: 24px;
    margin-left: 3px;
  }
}

.urlNames {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  span {
    color: #676d7a;
    font-size: 14px;

    font-weight: 400;
    line-height: 150%; /* 21px */
    text-transform: capitalize;
    margin-bottom: 0;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  .leftDash {
    width: 28px;
    height: 28px;
  }
}
