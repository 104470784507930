@import "./var";

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: "Graphik";
  background-color: $skyBlue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  color: $black;
  font-weight: $fontExtraBold;
  line-height: 1.5;
  font-size: $fontXL;
  text-align: left;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
  color: $secondary;
}
h5 {
  font-size: $fontLG;
  color: $secondary;
}
h6 {
  font-size: $fontMD;
}
p {
  color: $secondary;
  // font-size: $fontSM;
  font-weight: $fontNormal;
  line-height: 1.5;
}
li {
  font-size: $fontSM;
}
